import { format } from 'date-fns';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
declare const window: any;

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    whitelist: [
      'cz',
      'de',
      'en',
      'es',
      'fi',
      'fr',
      'gr',
      'is',
      'it',
      'jp',
      'nl',
      'no',
      'pt',
      'ru',
      'sv'
    ],
    fallbackLng: 'en',
    defaultNS: 'locales',
    debug: false,
    // saveMissing: true, // send not translated keys to endpoint
    // saveMissingTo: 'fallback',
    // appendNamespaceToMissingKey: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, formatString, lng) => {
        if (value instanceof Date) {
          const date = format(value, formatString || 'dd / MM / YYYY');
          return date || value;
        }
        return value;
      }
    },

    // missingKeyHandler: (lan, ns, keys, fallbackValue) => { return; },
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: window?._env_?.A365_LANG_LOCATION && window?._env_?.A365_LANG_LOCATION?.trim() !== '' ?
        window?._env_?.A365_LANG_LOCATION :
        '/{{ns}}/{{lng}}/translation.json'
      // appendNamespaceToMissingKey: true
      // path to post missing resources
      // addPath: __dirname + '/localhost:8080/translation'
    }
  });

export default i18n;
