import Link, { LinkProps } from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const styles = makeStyles((theme: Theme) => createStyles({
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

interface IProps extends Omit<LinkProps, 'classes'> {
  component?: any;
}

export default ({component, ...props}: IProps) => {
  const classes = styles();

  return (
    <Link
      className={classes.link}
      component={component}
      color={props.color || 'secondary'}
      href={props.href}
      underline={props.underline || 'always'}
      {...props}
    />
  );
};
