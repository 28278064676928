import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Omit } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';

interface IProps extends Omit<RouteProps, 'component'> {
  roleAccess?: string[];
  accessLevel?: string[];
  component: React.ReactType;
}

const CheckRole = ({component: Component, roleAccess = [], accessLevel = [], ...props}: IProps) => {
  const { t } = useTranslation();

  const userHasRole = user.hasRole(roleAccess);
  const userHasAccessLevel = user.hasAccessLevel(accessLevel);

  if ((roleAccess.length === 0 && accessLevel.length === 0) || (userHasRole && userHasAccessLevel)) {
    return <Component {...props} />;
  }

  return (
    <div>
      {t('Permission')}
    </div>
  );
};

export default ({ component: Component, roleAccess, accessLevel, ...rest }: IProps) => {
  return (
    <Route
      {...rest}
      render={(props: any) => (
        user.signedIn ? (
          <CheckRole component={Component} roleAccess={roleAccess} accessLevel={accessLevel} {...props} />
        ) : (
          <Redirect to='/sign-in' />
        )
      )}
    />
  );
};
