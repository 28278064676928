// Because of TypeScript conflicts, this needs to be a JavaSctip file

import { cast, types } from 'mobx-state-tree';

import { ITranslation } from '../../../../helpers/typeHelpers';
import Message from '../Message';

const Activity = types.model('Activity', {
  loading: false,
  lastActivity: types.Date,
  messages: types.array(Message)
}).actions(self => ({
  clearMessages() {
    self.messages = cast([]);
  },

  registerActivity() {
    self.lastActivity = new Date();
  },

  setLoading(loading) {
    self.loading = loading;
  },

  addMessage(message: string | ITranslation, variant: 'error' | 'info' | 'success' | 'warning') {
    self.messages.push({message, variant});
  },
  removeMessage(index) {
    self.messages.splice(index, 1);
  }
}));

export const activity = Activity.create({
  lastActivity: new Date()
});

export default Activity;
