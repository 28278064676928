import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { reactPlugin } from './AppInsights';

const AppInsightsContext = createContext(reactPlugin);

const AppInsightsContextProvider = ({ children }) => {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

const useAppInsightsContext = () => useContext(AppInsightsContext);

const useCustomEvent = (reactPl, eventName, eventData?, skipFirstRun = true) => {
    const [data, setData] = useState(eventData);
    const firstRun = useRef(skipFirstRun);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return;
        }
        reactPl.trackEvent({ name: eventName }, data);
    }, [reactPl, eventName, data]);

    return setData;
};

export default useCustomEvent;

export { AppInsightsContext, AppInsightsContextProvider, useAppInsightsContext };
