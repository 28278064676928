import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest
} from '@azure/msal-browser';
import history from '../../../config/history';
import { getPath } from '../../../helpers/helperFunctions';
import { config } from '../../base/models/Config';
import { user } from '../models/User';

import { getMsalConfig } from './azure-authentication-config';

export class AzureAuthenticationContext {

  public isAuthenticationConfigured = false;

  private myMsalObj: PublicClientApplication;

  private account?: AccountInfo | null;
  private loginRedirectRequest?: RedirectRequest;
  private loginRequest?: PopupRequest;

  constructor() {
    const msalConfig = getMsalConfig();
    this.myMsalObj = new PublicClientApplication(
      msalConfig
    );

    this.account = null;
    this.setRequestObjects();
    if (msalConfig?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  public async login(signInType: string, setUser: any): Promise<any> {
    config.updateUserLoginB2C(true);
    user.updateUserLoginB2C();
    if (signInType === 'loginPopup') {
      this.myMsalObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          return this.handleResponse(resp, setUser);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (signInType === 'loginRedirect') {
      this.myMsalObj.loginRedirect(this.loginRedirectRequest);
    }
  }

  public async loadAuthModule(): Promise<any> {
    this.myMsalObj
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        if (resp) {
          this.handleResponse(resp, user.returnedAccountInfo);
        }
        if (user.isB2CAuthenticated && !user.dealer) {
          user.showSignInDialogAfterB2C();
        }
        if (user.isB2CAuthenticated && user.dealer) {
          if (getPath() === '/sign-in') {
            history.push('/');
          }
        }
      })
      .catch(console.error);
  }

  public logoutB2C(): void {
    const account = this.myMsalObj.getAccountByUsername(user.b2cUserName!)!;

    const logoutRequest: EndSessionRequest = {
      account
    };
    this.myMsalObj.logout(logoutRequest);

  }

  public async handleResponse(response: AuthenticationResult, incomingFunction: any) {
    if (response !== null && response.account !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }
    if (this.account) {
      incomingFunction(this.account);
      if (user.isB2CAuthenticated) {
        if (user.dealer === null) {
          const status: any = await user.authenticate('', user.b2cUserName!);
          if (status === 'success') {
            if (user.dealers.length <= 1) {
              if (user.dealers.length === 0) {
                user.setDealerError('No dealers found on your account please contact your system administrator');
                user.showB2CDealerDialog();
              } else {
                user.setDealer(user.dealers[0].id);
                user.hideB2CSignInDialog();
              }
            } else {
              user.showB2CDealerDialog();
            }
          } else {
            user.setMembershipError('Your account is not activated in service portal. Please contact your system administrator to send the invite.');
          }
        }
      }
    }
  }

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: ['openid', 'offline_access']
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href
    };
  }
  private getAccount(): AccountInfo | undefined {
    const currentAccounts = this.myMsalObj.getAllAccounts();
    if (currentAccounts === null) {
      return undefined;
    }

    if (currentAccounts.length > 1) {
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
}

export default AzureAuthenticationContext;
