import { types } from 'mobx-state-tree';
import Paging from '../../../base/models/Paging';
import CustomerPromotion from '../CustomerPromotion';

export default {
  fetchingcustomerPromotionList: false,
  customerPromotionList: types.array(CustomerPromotion),
  selectedCustomerPromotionList: types.maybeNull(CustomerPromotion),
  fetchNoContent: false,
  statuscode: types.maybeNull(types.number),
  message: types.maybeNull(types.string),
  paging: Paging,
  orderBy: types.string,
  ordering: types.enumeration('Ordering', ['asc', 'desc']),
  searchString: types.maybeNull(types.string)
};
