import { Instance, onSnapshot, types } from 'mobx-state-tree';

const Cookies = types
  .model('Cookies', {
    cookiesAccepted: false
  })
  .actions((self: any) => ({

    setCookies(accept: boolean) {
      self.cookiesAccepted = accept;
    }
  })
  );

export default Cookies;

let initialState = {};

if (localStorage.getItem('Cookies')) {
  const json = JSON.parse(localStorage.getItem('Cookies') || '{}');
  if (Cookies.is(json)) {
    initialState = {...json, ...initialState};
  }
}

export const cookies = Cookies.create(initialState);
export interface ICookies extends Instance<typeof Cookies> {}

onSnapshot(cookies, snapshot => {
  localStorage.setItem('Cookies', JSON.stringify(snapshot));
});
