import { applySnapshot, cast, clone, flow } from 'mobx-state-tree';
import { generateQueryString } from '../../../../helpers/helperFunctions';

import { A365Online } from '../../../../helpers/requests';
import { activity } from '../../../base/models/Activity';
import { formatPagingResponse } from '../../../base/models/Paging/helpers';
import { formatCustomerPromotionResponse, ICustomerPromotion, ICustomerPromotionResponse } from '../CustomerPromotion';

export default (self: any) => ({
  getCustomerPromotionList: flow(function* getCustomerPromotionList(append?: boolean) {
    try {
      self.fetchingcustomerPromotionList = true;
      const queryString = generateQueryString({
        searchString: self.searchString,
        orderBy: `${self.orderBy} ${self.ordering}`,
        pageNumber: self.paging ? self.paging.pageNumber : null,
        pageSize: self.paging ? self.paging.pageSize : null
      });
      const resp: any = yield A365Online.Get(`UserImages${queryString}`);
      self.paging = formatPagingResponse(resp);

      const promotionList: ICustomerPromotion[] = resp.results.map((promotionList: ICustomerPromotionResponse) =>
      formatCustomerPromotionResponse(promotionList)
    );

      if (append) {
      applySnapshot(self.customerPromotionList, [...self.customerPromotionList, ...promotionList]);
    } else {
      self.customerPromotionList = cast(promotionList);
    }

    } catch (err: any) {
      activity.addMessage(err.Message || 'Failed to fetch dealer promotion list', 'error');
    } finally {
      self.fetchingcustomerPromotionList = false;
    }
  }),

  openCustomerPromotion(index: string) {
    const selectedCustomerPromotionList = self.customerPromotionList[index];

    if (selectedCustomerPromotionList) {
      self.selectedCustomerPromotionList = clone(selectedCustomerPromotionList);
    }
  },

  clear() {
    if (self.customerPromotionList) {
      self.customerPromotionList.clear();
      self.fetchingcustomerPromotionList = false;
    }
  },

  setSearchString(searchString: string) {
    self.searchString = searchString;
  },

  setOrderBy(orderBy: string) {
    self.orderBy = orderBy;
  },

  setOrdering(ordering: 'asc' | 'desc') {
    self.ordering = ordering;
  },

  addToCustomerPromotionList: flow(function* addToCustomerPromotionList() {
    if (self.paging && !self.fetchingcustomerPromotionList && self.customerPromotionList.length < self.paging.totalItems) {
      self.paging.setPageNumber(self.paging.pageNumber + 1);
      yield self.getCustomerPromotionList(true);
    }
  })
});
