export default {
configurationLoading:false,
fieldConfigurationsTab: 0,
webClaimNumLine: true,
claimNumLine: true,
claimNameLine: true,
deviceNumberLine: true,
claimTypeLine: true,
createdDateLine: true,
createdByLine: true,
claimStatusLine: true,
approvalStatusLine: true,
totalClaimedAmountLine: true,
totalApprovedAmountLine: true,
warrantyClaimListPreWorkApproval: true,
warrantyClaimEmailNotification: true,
warrantyClaimDownloadStatement: true,
warrantyClaimDownloadInvoice: true,

deviceBrand: true,
deviceClass: true,
deviceModel: true,
deviceModelCode: true,
deviceServiceModel: true,
deviceNumber: true,
deviceRegistrationNumber: true,
deviceSerialNumber: true,
deviceVINNumber: true,
deviceUsage: true,

claimName: true,
claimGroup: true,
causalPart: true,
claimSerialNumber: true,
claimSummaryContractCode: true,
claimContractCode: true,
serviceCampaignNumber: true,
returnReasonCode: true,
externalReferenceNumber: true,
serviceStartDate: true,
serviceEndDate: true,
contactName: true,
contactEmail: true,
contactPhoneNumber: true,
damageSymptomCode: true,
damageCauseCode: true,
damageResolutionCode: true,
damageCodeA: true,
damageCodeB: true,
damageCodeC: true,
detailsSymptoms: true,
detailsCause: true,
detailsSolutions: true,
detailsNotes: true,
detailsPreWorkApproval: true,
operationNumber: true,
laborDescription: true,
laborReference: true,
laborStandardHours: true,
laborActualHours: true,
laborUnitPrice: true,
laborTotalPrice: true,
laborMainOprId: true,
partNumber: true,
partsDescription: true,
partsReference: true,
partsQty: true,
partsUnit: true,
partsUnitPrice: true,
partsTotalPrice: true,
partsLineCausalPart: true,
returnablePart: true,
miscCodes: true,
miscCodesDescription: true,
miscDealerReference: true,
miscChargesQty: true,
miscUnitPrice: true,
miscTotalPrice: true,
currency: true,
claimNum: true,
claimStatus: true,
approvalStatus: true,
claimSummaryClaimId: true,
claimSummaryDeviceNum: true,
claimSummaryVINNum: true,
claimSummaryRegistrationNum: true,
claimSummarySerialNum: true,
claimSummaryClaimType: true,
claimSummaryClaimGroup: true,
claimSummaryUsage: true,
laborApprovedQty: true,
partsApprovedQty: true,
miscApprovedQty: true,

webOrderIdList: true,
salesOrderNumberList: true,
dealerReferenceNumberList: true,
orderDateList: true,
createdByList: true,
orderTypeList: true,
statusList: true,
totalAmountList: true,
dealerRequisition: true,
dealerReferenceNumber: true,
partsOrderContactEmail: true,
partsOrderContactName: true,
partsOrderPhoneNumber: true,
partNumberLine: true,
partNameLine: true,
deviceReferenceLine: true,
dealerReferenceLine: true,
quantityLine: true,
partsLineSite: true,
partsLineWarehouse: true,
partsLineOnHand: true,
partsLineConfig: true,
partsLineSize: true,
partsLineColor: true,
partsLineStyle: true,
partsLineConfirmedReceiptDate: true,
partsLineConfirmedShipDate: true,
unitLine: true,
unitPriceLine: true,
discountLine: true,
discountPercentageLine: true,
totalAmountLine: true,
partsOrderCurrency: true,
salesOrderNumber: true,
status: true,
orderDate: true,
partsLineDeliverRemainder: true,
partsLineProductCatalog: true,
partsLineImportLines: true,
partsLineAddAttachments: true,

deviceOrderListWebOrderId: true,
deviceOrderListOrderNum: true,
deviceOrderListDealerRefNum: true,
deviceOrderListDate: true,
deviceOrderListCreatedBy: true,
deviceOrderListType: true,
deviceOrderListStatus: true,
deviceOrderListTotalAmount: true,
dodDealerReference: true,
dodDealerRequisition: true,
dodInvoiceAccount: true,
dodRequestInvoice: true,
dodContactName: true,
dodContactEmail: true,
dodContactPhoneNumber: true,
dodSalesOrderNum: true,
dodStatus: true,
dodDate: true,
dolItemNumber: true,
dolDeviceName: true,
dolDeviceNumber: true,
dolConfiguration: true,
dolConfirmedReceiptDate: true,
dolConfirmedShipDate: true,
dolExterior: true,
dolInterior: true,
dolStyle: true,
dolQuantity: true,
dolUnit: true,
docItemCode: true,
docConfiguration: true,
docExterior: true,
docInterior: true,
docStyle: true,
docCampaign: true,
docOrdered: true,
docInShipping: true,
docInStock: true,
docConsignment: true,
docRentalFleet: true,
dosCurrency: true,
deviceBackOrderDeleteOption: true,
partsBackOrderDeleteOption: true,
dolDeliverRemainder: true,

diListName: true,
diListBrand: true,
diListClass: true,
diListModel: true,
diListModelCode: true,
diListModelYear: true,
diListDeviceNum: true,
diListSerialNum: true,
diListRegNum: true,
diListVINNum: true,
diListStatus: true,
diGeneralDeviceNumber: true,
diGeneralDeviceName: true,
diGeneralMasterNum: true,
diGeneralRegNum: true,
diGeneralPrivateNum: true,
diGeneralMajorStatus: true,
diSetupBrandNum: true,
diSetupClassNum: true,
diSetupModelNum: true,
diSetupModelCodeNum: true,
diSetupModelConfig: true,
diSetupInteriorNum: true,
diSetupExteriorNum: true,
diSetupModelYear: true,
diSetupProductionDate: true,
diSetupCountryOrigin: true,
diSetupEngineDisplacement: true,
diSetupEngineHP: true,
diSetupEngineType: true,
diSetupBodyStyle: true,
diSetupFacelift: true,
diSetupFuelType: true,
diSetupNumCylinder: true,
diCustodianName: true,
diCustodianOwner: true,
diCustodianFirstOwner: true,
diCustodianOperator: true,
diCustodianCoOwner: true,
diCustodianActive: true,
diServiceCampaignLinesNum: true,
diServiceCampaignLinesDescription: true,
diServiceCampaignLinesStartDate: true,
diServiceCampaignLinesStatus: true,
diServiceCampaignLinesEndDate: true,
diDeviceAccessoriesSpecificationType: true,
diDeviceAccessoriesItemNum: true,
diDeviceAccessoriesDescription: true,
diDeviceAccessoriesComponentNum: true,
diDeviceAccessoriesCategory: true,
diDeviceAccessoriesQuantity: true,
diDeviceAccessoriesStocked: true,
diDeviceAccessoriesReplaced: true,
diDeviceAccessoriesStatus: true,
diDeviceAccessoriesTransactionDate: true,
diDeviceAccessoriesStatusDate: true,
diDeviceChildSpecificationType: true,
diDeviceChildItemNum: true,
diDeviceChildDescription: true,
diDeviceChildComponentNum: true,
diDeviceChildCategory: true,
diDeviceChildQuantity: true,
diDeviceChildStocked: true,
diDeviceChildReplaced: true,
diDeviceChildStatus: true,
diDeviceChildTransactionDate: true,
diDeviceChildStatusDate: true,
diDeviceComponentsSpecificationType: true,
diDeviceComponentsItemNum: true,
diDeviceComponentsDescription: true,
diDeviceComponentsNum: true,
diDeviceComponentsCategory: true,
diDeviceComponentsQuantity: true,
diDeviceComponentsStocked: true,
diDeviceComponentsReplaced: true,
diDeviceComponentsStatus: true,
diDeviceComponentsTranscationDate: true,
diDeviceComponentsStatusDate: true,
diOptionsSpecificationType: true,
diOptionsItemNum: true,
diOptionsDescription: true,
diOptionsComponentNum: true,
diOptionsCategory: true,
diOptionsQuantity: true,
diOptionsStocked: true,
diOptionsReplaced: true,
diOptionsStatus: true,
diOptionsTransactionDate: true,
diOptionsStatusDate: true,
diDevicePropertiesDeviceName: true,
diDevicePropertiesDeviceNum: true,
diDevicePropertiesPropertyGroup: true,
diDevicePropertiesPropertyGroupCode: true,
diDevicePropertiesNotes: true,
diWarrantyType: true,
diWarrantyDescription: true,
diWarrantStartDate: true,
diWarrantyEndDate: true,
diWarrantyStartUsage: true,
diWarrantyEndUsage: true,
diWorkOrderHistNum: true,
diWorkOrderHistDescription: true,
diWorkOrderHistCustomerAccount: true,
diWorkOrderHistStatus: true,
diWorkOrderHistWorkOrderGrp: true,
diWorkOrderHistType: true,
diWorkOrderHistStartDate: true,
diWorkOrderHistCreatedDate: true,
diWorkOrderHistEstimatedHours: true,
diWorkOrderHistHoursRemaining: true,
diClaimHistWarrantyClaim: true,
diClaimHistStatus: true,
diClaimHistApprovalStatus: true,
diClaimHistInvoiceStatus: true,
diClaimHistWarrantyClaimGrp: true,
diClaimHistType: true,
diClaimHistDevice: true,
diClaimHistPreWorkApproval: true,
diClaimHistUsage: true,
diServicePlanNum: true,
diServicePlanName: true,
diServicePlanStatus: true,
diServicePlanActivation: true,
diServicePlanReferenceDate: true,
diServicePlanContractNum: true,
diServicePlanGrp: true,
diScheduledServiceDueDate: true,
diScheduledServiceUsage: true,
diScheduledServiceUsageUnit: true,
diScheduledServiceUsageDueDate: true,
diScheduledServiceName: true,
diScheduledServiceServicePlanGrp: true,
diScheduledServiceStatus: true,
diScheduledServiceStopped: true,
diScheduledServiceWorkOrderNum: true,
diScheduledServiceWorkOrderStatus: true,
diScheduledServiceClosedDate: true,
diDocumentsName: true,
diDocumentsType: true,
diDocumentsNotes: true,
diServiceContractContractId: true,
diServiceContractDeviceId: true,
diServiceContractDeviceGroupId: true,
diServiceContractInvoiceContractId: true,
diServiceContractContractType: true,
diServiceContractCollectionStatus: true,
diServiceContractCustAccount: true,
diServiceContractName: true,

drListWebId: true,
drListCreatedBy: true,
drListCreatedDate: true,
drListStatus: true,
drInfoHeaderWebId: true,
drInfoHeaderReportNum: true,
drInfoHeaderCreatedBy: true,
drInfoHeaderCreatedDate: true,
drInfoHeaderStatus: true,
drInfoLinesDeviceInfoVinNum: true,
drInfoLinesDeviceInfoDeviceNum: true,
drInfoLinesDeviceInfoSerialNum: true,
drInfoLinesGeneralUsage: true,
drInfoLinesGeneralUsageGrp: true,
drInfoLinesGeneralMjrStatus: true,
drInfoLinesGeneralFinalSalesDate: true,
drInfoLinesGeneralRegDate: true,
drInfoLinesGeneralRefDate: true,
drCreateGeneralRegNum: true,
drInfoLinesGeneralWarrantyType: true,
drInfoLinesParentDeviceInfoVinNum: true,
drInfoLinesParentDeviceInfoParentDeviceNum: true,
drInfoLinesParentDeviceInfoSerialNum: true,
drInfoLinesParentDeviceInfoUpdateParentDeviceCustodian: true,
drInfoLinesCustodianName: true,
drInfoLinesCustodianEmail: true,
drInfoLinesCustodianPhoneNum: true,
drInfoLinesCustodianAddress: true,
drCreateLineVinNum: true,
drCreateLineDeviceNum: true,
drCreateLineSerialNum: true,
drCreateGeneralUsage: true,
drCreateGeneralUsageGrp: true,
drCreateGeneralMjrStatus: true,
drCreateGeneralFinalSalesDate: true,
drCreateGeneralRegDate: true,
drCreateGeneralRefDate: true,
drCreateGeneralWarrantyType: true,
drCreateParentDeviceVinNum: true,
drCreateParentDeviceNum: true,
drCreateParentDeviceSerialNum: true,
drCreateParentDeviceUpdateParentDeviceCustodian: true,
drCreateCustodianName: true,
drCreateCustodianEmail: true,
drCreateCustodianPhoneNum: true,
drCreateCustodianAddress: true,
drCreatePDIDealer:true,
drCreateTechnicalID:true,

maintenancePlanAssignServicePlan: true,

daListDevicNumber: true,
daListDeviceName: true,
daListItemNumber: true,
daListModelCodeNumber: true,
daListConfigName: true,
daListModelYear: true,
daListRegistrationNumber: true,
daListChassislNum: true,
daListMajorStatus: true,
daListSalesPrice: true,
daListCampaignPrice: true,
daGeneralDeviceNumber: true,
daGeneralDeviceName: true,
daGeneralMasterNum: true,
daGeneralRegNum: true,
daGeneralPrivateNum: true,
daGeneralMajorStatus: true,
daSetupBrandNum: true,
daSetupClassNum: true,
daSetupModelNum: true,
daSetupModelCodeNum: true,
daSetupModelConfig: true,
daSetupInteriorNum: true,
daSetupExteriorNum: true,
daSetupModelYear: true,
daSetupProductionDate: true,
daSetupCountryOrigin: true,
daSetupEngineDisplacement: true,
daSetupEngineHP: true,
daSetupEngineType: true,
daSetupBodyStyle: true,
daSetupFacelift: true,
daSetupFuelType: true,
daSetupNumCylinder: true,
daCustodianName: true,
daCustodianOwner: true,
daCustodianFirstOwner: true,
daCustodianOperator: true,
daCustodianCoOwner: true,
daCustodianActive: true,
daServiceCampaignLinesNum: true,
daServiceCampaignLinesDescription: true,
daServiceCampaignLinesStartDate: true,
daServiceCampaignLinesStatus: true,
daServiceCampaignLinesEndDate: true,
daDeviceAccessoriesSpecificationType: true,
daDeviceAccessoriesItemNum: true,
daDeviceAccessoriesDescription: true,
daDeviceAccessoriesComponentNum: true,
daDeviceAccessoriesCategory: true,
daDeviceAccessoriesQuantity: true,
daDeviceAccessoriesStocked: true,
daDeviceAccessoriesReplaced: true,
daDeviceAccessoriesStatus: true,
daDeviceAccessoriesTransactionDate: true,
daDeviceAccessoriesStatusDate: true,
daDeviceChildSpecificationType: true,
daDeviceChildItemNum: true,
daDeviceChildDescription: true,
daDeviceChildComponentNum: true,
daDeviceChildCategory: true,
daDeviceChildQuantity: true,
daDeviceChildStocked: true,
daDeviceChildReplaced: true,
daDeviceChildStatus: true,
daDeviceChildTransactionDate: true,
daDeviceChildStatusDate: true,
daDeviceComponentsSpecificationType: true,
daDeviceComponentsItemNum: true,
daDeviceComponentsDescription: true,
daDeviceComponentsNum: true,
daDeviceComponentsCategory: true,
daDeviceComponentsQuantity: true,
daDeviceComponentsStocked: true,
daDeviceComponentsReplaced: true,
daDeviceComponentsStatus: true,
daDeviceComponentsTranscationDate: true,
daDeviceComponentsStatusDate: true,
daOptionsSpecificationType: true,
daOptionsItemNum: true,
daOptionsDescription: true,
daOptionsComponentNum: true,
daOptionsCategory: true,
daOptionsQuantity: true,
daOptionsStocked: true,
daOptionsReplaced: true,
daOptionsStatus: true,
daOptionsTransactionDate: true,
daOptionsStatusDate: true,
daDevicePropertiesDeviceName: true,
daDevicePropertiesDeviceNum: true,
daDevicePropertiesPropertyGroup: true,
daDevicePropertiesPropertyGroupCode: true,
daDevicePropertiesNotes: true,
daWarrantyType: true,
daWarrantyDescription: true,
daWarrantStartDate: true,
daWarrantyEndDate: true,
daWarrantyStartUsage: true,
daWarrantyEndUsage: true,
daWorkOrderHistNum: true,
daWorkOrderHistDescription: true,
daWorkOrderHistCustomerAccount: true,
daWorkOrderHistStatus: true,
daWorkOrderHistWorkOrderGrp: true,
daWorkOrderHistType: true,
daWorkOrderHistStartDate: true,
daWorkOrderHistCreatedDate: true,
daWorkOrderHistEstimatedHours: true,
daWorkOrderHistHoursRemaining: true,
daClaimHistWarrantyClaim: true,
daClaimHistStatus: true,
daClaimHistApprovalStatus: true,
daClaimHistInvoiceStatus: true,
daClaimHistWarrantyClaimGrp: true,
daClaimHistType: true,
daClaimHistDevice: true,
daClaimHistPreWorkApproval: true,
daClaimHistUsage: true,
daServicePlanNum: true,
daServicePlanName: true,
daServicePlanStatus: true,
daServicePlanActivation: true,
daServicePlanReferenceDate: true,
daServicePlanContractNum: true,
daServicePlanGrp: true,
daScheduledServiceDueDate: true,
daScheduledServiceUsage: true,
daScheduledServiceUsageUnit: true,
daScheduledServiceUsageDueDate: true,
daScheduledServiceName: true,
daScheduledServiceServicePlanGrp: true,
daScheduledServiceStatus: true,
daScheduledServiceStopped: true,
daScheduledServiceWorkOrderNum: true,
daScheduledServiceWorkOrderStatus: true,
daScheduledServiceClosedDate: true,
daDocumentsName: true,
daDocumentsType: true,
daDocumentsNotes: true,
daServiceContractContractId: true,
daServiceContractDeviceId: true,
daServiceContractDeviceGroupId: true,
daServiceContractInvoiceContractId: true,
daServiceContractContractType: true,
daServiceContractCollectionStatus: true,
daServiceContractCustAccount: true,
daServiceContractName: true,

ddListDevicNumber: true,
ddListDeviceName: true,
ddListItemNumber: true,
ddListModelCodeNumber: true,
ddListConfigName: true,
ddListModelYear: true,
ddListRegistrationNumber: true,
ddListChassislNum: true,
ddListMajorStatus: true,
ddListSalesPrice: true,
ddListCampaignPrice: true,
ddGeneralDeviceNumber: true,
ddGeneralDeviceName: true,
ddGeneralMasterNum: true,
ddGeneralRegNum: true,
ddGeneralPrivateNum: true,
ddGeneralMajorStatus: true,
ddSetupBrandNum: true,
ddSetupClassNum: true,
ddSetupModelNum: true,
ddSetupModelCodeNum: true,
ddSetupModelConfig: true,
ddSetupInteriorNum: true,
ddSetupExteriorNum: true,
ddSetupModelYear: true,
ddSetupProductionDate: true,
ddSetupCountryOrigin: true,
ddSetupEngineDisplacement: true,
ddSetupEngineHP: true,
ddSetupEngineType: true,
ddSetupBodyStyle: true,
ddSetupFacelift: true,
ddSetupFuelType: true,
ddSetupNumCylinder: true,
ddCustodianName: true,
ddCustodianOwner: true,
ddCustodianFirstOwner: true,
ddCustodianOperator: true,
ddCustodianCoOwner: true,
ddCustodianActive: true,
ddServiceCampaignLinesNum: true,
ddServiceCampaignLinesDescription: true,
ddServiceCampaignLinesStartDate: true,
ddServiceCampaignLinesStatus: true,
ddServiceCampaignLinesEndDate: true,
ddDeviceAccessoriesSpecificationType: true,
ddDeviceAccessoriesItemNum: true,
ddDeviceAccessoriesDescription: true,
ddDeviceAccessoriesComponentNum: true,
ddDeviceAccessoriesCategory: true,
ddDeviceAccessoriesQuantity: true,
ddDeviceAccessoriesStocked: true,
ddDeviceAccessoriesReplaced: true,
ddDeviceAccessoriesStatus: true,
ddDeviceAccessoriesTransactionDate: true,
ddDeviceAccessoriesStatusDate: true,
ddDeviceChildSpecificationType: true,
ddDeviceChildItemNum: true,
ddDeviceChildDescription: true,
ddDeviceChildComponentNum: true,
ddDeviceChildCategory: true,
ddDeviceChildQuantity: true,
ddDeviceChildStocked: true,
ddDeviceChildReplaced: true,
ddDeviceChildStatus: true,
ddDeviceChildTransactionDate: true,
ddDeviceChildStatusDate: true,
ddDeviceComponentsSpecificationType: true,
ddDeviceComponentsItemNum: true,
ddDeviceComponentsDescription: true,
ddDeviceComponentsNum: true,
ddDeviceComponentsCategory: true,
ddDeviceComponentsQuantity: true,
ddDeviceComponentsStocked: true,
ddDeviceComponentsReplaced: true,
ddDeviceComponentsStatus: true,
ddDeviceComponentsTranscationDate: true,
ddDeviceComponentsStatusDate: true,
ddOptionsSpecificationType: true,
ddOptionsItemNum: true,
ddOptionsDescription: true,
ddOptionsComponentNum: true,
ddOptionsCategory: true,
ddOptionsQuantity: true,
ddOptionsStocked: true,
ddOptionsReplaced: true,
ddOptionsStatus: true,
ddOptionsTransactionDate: true,
ddOptionsStatusDate: true,
ddDevicePropertiesDeviceName: true,
ddDevicePropertiesDeviceNum: true,
ddDevicePropertiesPropertyGroup: true,
ddDevicePropertiesPropertyGroupCode: true,
ddDevicePropertiesNotes: true,
ddWarrantyType: true,
ddWarrantyDescription: true,
ddWarrantStartDate: true,
ddWarrantyEndDate: true,
ddWarrantyStartUsage: true,
ddWarrantyEndUsage: true,
ddWorkOrderHistNum: true,
ddWorkOrderHistDescription: true,
ddWorkOrderHistCustomerAccount: true,
ddWorkOrderHistStatus: true,
ddWorkOrderHistWorkOrderGrp: true,
ddWorkOrderHistType: true,
ddWorkOrderHistStartDate: true,
ddWorkOrderHistCreatedDate: true,
ddWorkOrderHistEstimatedHours: true,
ddWorkOrderHistHoursRemaining: true,
ddClaimHistWarrantyClaim: true,
ddClaimHistStatus: true,
ddClaimHistApprovalStatus: true,
ddClaimHistInvoiceStatus: true,
ddClaimHistWarrantyClaimGrp: true,
ddClaimHistType: true,
ddClaimHistDevice: true,
ddClaimHistPreWorkApproval: true,
ddClaimHistUsage: true,
ddServicePlanNum: true,
ddServicePlanName: true,
ddServicePlanStatus: true,
ddServicePlanActivation: true,
ddServicePlanReferenceDate: true,
ddServicePlanContractNum: true,
ddServicePlanGrp: true,
ddScheduledServiceDueDate: true,
ddScheduledServiceUsage: true,
ddScheduledServiceUsageUnit: true,
ddScheduledServiceUsageDueDate: true,
ddScheduledServiceName: true,
ddScheduledServiceServicePlanGrp: true,
ddScheduledServiceStatus: true,
ddScheduledServiceStopped: true,
ddScheduledServiceWorkOrderNum: true,
ddScheduledServiceWorkOrderStatus: true,
ddScheduledServiceClosedDate: true,
ddDocumentsName: true,
ddDocumentsType: true,
ddDocumentsNotes: true,
ddServiceContractContractId: true,
ddServiceContractDeviceId: true,
ddServiceContractDeviceGroupId: true,
ddServiceContractInvoiceContractId: true,
ddServiceContractContractType: true,
ddServiceContractCollectionStatus: true,
ddServiceContractCustAccount: true,
ddServiceContractName: true,

piReleasedGeneralItemNumber: true,
piReleasedGeneralProductName: true,
piReleasedGeneralUnit: true,
piReleasedGeneralListPrice: true,
piReleasedSetupNetWeight: true,
piReleasedSetupTareWeight: true,
piReleasedSetupGrossWeight: true,
piReleasedSetupGrossDepth: true,
piReleasedSetupGrossWidth: true,
piReleasedSetupGrossHeight: true,
piReleasedSetupHazardousMaterial: true,
piReleasedSetupBlocked: true,
piReleasedSetupReasonCode: true,
piReleasedSetupBrand: true,
piReleasedSetupClass: true,
piReleasedSetupModel: true,
piReleasedItemChangeHeaderItemChange: true,
piReleasedItemChangeHeaderItemNumber: true,
piReleasedItemChangeHeaderName: true,
piReleasedItemChangeHeaderProductName: true,
piReleasedItemChangeHeaderDescription: true,
piReleasedItemChangeHeaderStatus: true,
piReleasedItemChangeLineItemQty: true,
piReleasedItemChangeLineItemNumber: true,
piReleasedItemChangeLineName: true,
piReleasedItemChangeLineUnit: true,
piReleasedItemChangeLineUniDirectional: true,
piReleasedOnHandQtyItemNumber: true,
piReleasedOnHandQtyName: true,
piReleasedOnHandQtyConfiguration: true,
piReleasedOnHandQtySize: true,
piReleasedOnHandQtyColor: true,
piReleasedOnHandQtyStyle: true,
piReleasedOnHandQtySerialNumber: true,
piReleasedOnHandQtySite: true,
piReleasedOnHandQtyWareHouse: true,
piReleasedOnHandQtyLocation: true,
piReleasedOnHandQtyLicensePlate: true,
piReleasedOnHandQtyQty: true,
piReleasedAlternativeItemsNumber: true,
piReleasedAlternativeItemsProductName: true,
piReleasedAlternativeItemsAlternativeConfig: true,
piReleasedAlternativeItemsSize: true,
piReleasedAlternativeItemsColor: true,
piReleasedAlternativeItemsStyle: true,
piReleasedSuppItemsSuppItem: true,
piReleasedSuppItemsProductName: true,
piReleasedSuppItemsConfig: true,
piReleasedSuppItemsSize: true,
piReleasedSuppItemsColor: true,
piReleasedSuppItemsStyle: true,
piReleasedSuppItemsQty: true,
piReleasedSuppItemsUnit: true,
piReleasedSuppItemsFreeOfCharge: true,
piReleasedSuppItemsItemType: true,
piReleasedSuppItemsOnlyOnce: true,
piReleasedBomBom: true,
piReleasedBomName: true,
piReleasedBomItemNumber: true,
piReleasedBomProductName: true,
piReleasedBomSubBom: true,
piReleasedBomConfig: true,
piReleasedBomSize: true,
piReleasedBomColor: true,
piReleasedBomStyle: true,
piReleasedBomQty: true,
piReleasedBomFromDate: true,
piReleasedBomToDate: true,
piReleasedSalesPriceCurrency: true,
piReleasedSalesPriceAccountSelection: true,
piReleasedSalesPriceConfig: true,
piReleasedSalesPriceSize: true,
piReleasedSalesPriceColor: true,
piReleasedSalesPriceStyle: true,
piReleasedSalesPriceFromDate: true,
piReleasedSalesPriceToDate: true,
piReleasedSalesPriceFrom: true,
piReleasedSalesPriceTo: true,
piReleasedSalesPricePrice: true,
piReleasedLineDiscountCurrency: true,
piReleasedLineDiscountAccountSelection: true,
piReleasedLineDiscountPartRelation: true,
piReleasedLineDiscountConfig: true,
piReleasedLineDiscountSize: true,
piReleasedLineDiscountColor: true,
piReleasedLineDiscountStyle: true,
piReleasedLineDiscountFromDate: true,
piReleasedLineDiscountToDate: true,
piReleasedLineDiscountFrom: true,
piReleasedLineDiscountTo: true,
piReleasedLineDiscountAmountInTransactionCurrency: true,
piReleasedLineDiscountDiscountPct1: true,
piReleasedLineDiscountDiscountPct2: true,
piSupplierGeneralBrand: true,
piSupplierGeneralDiscontinued: true,
piSupplierGeneralBlockedPrdctCreation: true,
piSupplierGeneralHeight: true,
piSupplierGeneralNetWeight: true,
piSupplierGeneralSuppItemNum: true,
piSupplierGeneralRefurbished: true,
piSupplierGeneralSalesPrice: true,
piSupplierGeneralWidth: true,
piSupplierGeneralSuppItemName: true,
piSupplierGeneralReturnItem: true,
piSupplierGeneralDepth: true,
piSupplierItemChangeHeaderItemChange: true,
piSupplierItemChangeHeaderSuppItemNum: true,
piSupplierItemChangeHeaderName: true,
piSupplierItemChangeHeaderBrandNum: true,
piSupplierItemChangeHeaderDescription: true,
piSupplierItemChangeHeaderStatus: true,
piSupplierItemChangeLineBrandNumber: true,
piSupplierItemChangeLineSuppItemNum: true,
piSupplierItemChangeLineName: true,
piSupplierItemChangeLineQty: true,
piSupplierItemChangeLineUnit: true,
piSupplierItemChangeLineUniDirectional: true,
piSupplierSuppItemsBrandNum: true,
piSupplierSuppitemsSuppItemNum: true,
piSupplierSuppItemsReturnItemBehavior: true,
piSupplierSuppItemsNum: true,
piSupplierSuppItemsItemName: true,
piReleasedSupplierItemBrandNum: true,
piReleasedSupplierItemSuppItemNum: true,
piReleasedSupplierItemSuppWarehouse: true,
piReleasedSupplierItemQty: true,
piReleasedSupplierItemOnHandDate: true,
piSupplierItemOnHandBrandNum: true,
piSupplierItemOnHandSuppItemNum: true,
piSupplierItemOnHandSuppWarehouse: true,
piSupplierItemOnHandQty: true,
piSupplierItemOnHandOnHandDate: true,

scListNum: true,
scListManufacturerServiceCampaign: true,
scListDescription: true,
scListBrand: true,
scListStartDate: true,
scListEndDate: true,
scListStatus: true,
scListCampaignType: true,
scInfoGeneralNum: true,
scInfoGeneralDescription: true,
scInfoGeneralExtendedEndDate: true,
scInfoGeneralNotes: true,
scInfoGeneralStartDate: true,
scInfoGeneralStatus: true,
scInfoGeneralBrandNum: true,
scInfoGeneralEndDate: true,
scInfoDamageSymptomCode: true,
scInfoDamageFailureCodeA: true,
scInfoDamageCauseCOde: true,
scInfoDamageFailureCodeB: true,
scInfoDamageResolutionCode: true,
scInfoDamageFailureCodeC: true,
scInfoLinesDeviceNum: true,
scInfoLinesChassisNum: true,
scInfoLinesName: true,
scInfoLinesServiceCampaignStatus: true,
scInfoLinesServiceCampaignCloseDate: true,
scInfoDocumentsName: true,
scInfoDocumentsType: true,
scInfoDocumentsNotes: true,

piListInvoiceNum: true,
piListSalesOrderNum: true,
piListInvoiceDate: true,
piListInvoiceAmount: true,
piListCurrency: true,
piDetailsGeneralInvoiceNum: true,
piDetailsGeneralInvoiceDate: true,
piDetailsGeneralCurrency: true,
piDetailsSalesOrderNum: true,
piDetailsShipToAddress: true,
piDetailsLinesPartNum: true,
piDetailsLinesPartName: true,
piDetailsLinesDeviceRef: true,
piDetailsLinesStatus: true,
piDetailsLinesQty: true,
piDetailsLinesUnitPrice: true,
piDetailsLinesDiscount: true,
piDetailsLinesDiscountPct: true,
piDetailsLinesTotalPrice: true,
piDetailsLinesSize: true,
piDetailsLinesColor: true,
piDetailsLinesConfig: true,
piDetailsLinesStyle: true,

diListInvoiceNum: true,
diListSalesOrderNum: true,
diListInvoiceDate: true,
diListInvoiceAmount: true,
diListCurrency: true,
diListDueDate: true,
diDetailsGeneralInvoiceNum: true,
diDetailsGeneralInvoiceDate: true,
diDetailsGeneralCurrency: true,
diDetailsGeneralDueDate: true,
diDetailsSalesOrderNum: true,
diDetailsShipToAddress: true,
diDetailsYourOrderDeviceConfigNum: true,
diDetailsYourOrderDeviceConfigName: true,
diDetailsYourOrderDeviceNum: true,
diDetailsYourOrderQty: true,
diDetailsYourOrderUnit: true,
diDetailsYourOrderUnitPrice: true,
diDetailsYourOrderDiscount: true,
diDetailsYourOrderDisccountPct: true,
diDetailsYourOrderTotalAmount: true,

dboListReqShipDate: true,
dboListConfirmedShipDate: true,
dboListSalesOrder: true,
dboListItemNum: true,
dboListDescription: true,
dboListDeviceNum: true,
dboListQty: true,
dboListDeliveryReminder: true,
dboListUnit: true,
dboListStatus: true,

pboListReqShipDate: true,
pboListConfirmedShipDate: true,
pboListSalesOrder: true,
pboListItemNum: true,
pboListDescription: true,
pboListDeviceNum: true,
pboListQty: true,
pboListDeliveryReminder: true,
pboListUnit: true,
pboListStatus: true,

proListWebOrderId: true,
proListReturnOrderNum: true,
proListRMANum: true,
proListReturnReasonCode: true,
proListCreatedBy: true,
proListCreatedDate: true,
proListStatus: true,
proSummaryGeneralRMANum: true,
proSummaryGeneralReturnReasonCode: true,
proSummaryGeneralDealerRefNum: true,
proSummaryGeneralRequisition: true,
proSummaryGeneralReturnOrderNum: true,
proSummaryLinesPartNum: true,
proSummaryLinesPartName: true,
proSummaryLinesDeviceRef: true,
proSummaryLinesStatus: true,
proSummaryLinesQty: true,
proSummaryLinesUnitPrice: true,
proSummaryLinesDiscount: true,
proSummaryLinesDiscountPct: true,
proSummaryLinesTotalPrice: true,
proSummaryLinesSize: true,
proSummaryLinesColor: true,
proSummaryLinesConfig: true,
proSummaryLinesStyle: true,

cListWebCaseId: true,
cListCaseId: true,
cListDescription: true,
cListCreatedBy: true,
cListCreadtedDate: true,
cListStatus: true,
ciGeneralCategory: true,
ciGeneralStatus: true,
ciGeneralCreatedDate: true,
ciGeneralPriority: true,
ciGeneralNotes: true,
ciGeneralDescription: true,
ciGeneralCreatedBy: true,
ciGeneralDepartment: true,
ciGeneralCaseResolution: true,
ciGeneralCaseId: true,
ciGeneralCaseProcess: true,
ciGeneralParentCase: true,
ciCaseLogCreatedDate: true,
ciCaseLogDescription: true,
ciCaseLogCreatedBy: true,
ciCaseLogNotes: true,
ccGeneralCategoryType: true,
ccGeneralCaseCategory: true,
ccGeneralParentCase: true,
ccGeneralPriority: true,
ccGeneralDescription: true,
ccContactName: true,
ccContactEmail: true,
ccContactPhoneNumber: true,
ccNotes: true,
ccCaseLogDescription: true,
ccCaseLogNotes: true,
ccAttachments: true,

pqListWebQuotationId: true,
pqListQuotationNumber: true,
pqListDealerReferenceNumber: true,
pqListOrderDate: true,
pqListSalesOrderNumber: true,
pqListCreatedBy: true,
pqListOrderType: true,
pqListStatus: true,
pqListTotalAmount: true,
pqDetailsDealerRequisition: true,
pqDetailsDealerReferenceNumber: true,
pqDetailsPartsQuotationContactEmail: true,
pqDetailsPartsQuotationContactName: true,
pqDetailsPartsQuotationPhoneNumber: true,
pqDetailsQuotationNumber: true,
pqDetailsStatus: true,
pqDetailsQuotationDate: true,
pqLinesPartNumber: true,
pqLinesPartName: true,
pqLinesDeviceReference: true,
pqLinesDealerReference: true,
pqLinesConfirmedShipDate: true,
pqLinesConfirmedReceiptDate: true,
pqLinesQuantity: true,
pqLinesSite: true,
pqLinesWarehouse: true,
pqLinesOnHand: true,
pqLinesConfig: true,
pqLinesSize: true,
pqLinesColor: true,
pqLinesStyle: true,
pqLinesUnit: true,
pqLinesUnitPrice: true,
pqLinesDiscount: true,
pqLinesDiscountPercentage: true,
pqLinesTotalAmount: true,
pqLinesDeliverReminder: true,
pqLinesPartsQuotationCurrency: true,
pqLinesProductCatalog: true,
pqLinesImportLines: true,
pqLinesAddAttachments: true,
pqCreateQuotation: true,

dqListWebQuotationId: true,
dqListQuotationNum: true,
dqListSalesOrderNum: true,
dqListDealerRefNum: true,
dqListDate: true,
dqListCreatedBy: true,
dqListStatus: true,
dqListTotalAmount: true,
dqCreateQuotation: true,
dqConfigurationCurrency: true,
dqDetailsDealerReference: true,
dqDetailsDealerRequisition: true,
dqDetailsInvoiceAccount: true,
dqDetailsRequestInvoice: true,
dqDetailsContactName: true,
dqDetailsContactEmail: true,
dqDetailsContactPhoneNumber: true,
dqDetailsSalesOrderNum: true,
dqDetailsStatus: true,
dqDetailsDate: true,
dqLinesItemNumber: true,
dqLinesDeviceName: true,
dqLinesDeviceNumber: true,
dqLinesConfiguration: true,
dqLinesExterior: true,
dqLinesInterior: true,
dqLinesStyle: true,
dqLinesQuantity: true,
dqLinesUnit: true,
dqLinesConfirmedShipDate: true,
dqLinesConfirmedReceiptDate: true,
dqLinesDeliverRemainder: true,
dqConfigurationItemCode: true,
dqConfigurationConfiguration: true,
dqConfigurationExterior: true,
dqConfigurationInterior: true,
dqConfigurationStyle: true,
dqConfigurationCampaign: true,
dqConfigurationOrdered: true,
dqConfigurationInShipping: true,
dqConfigurationInStock: true,
dqConfigurationConsignment: true,
dqConfigurationRentalFleet: true,

fetching: false
}
