import { Instance, types } from 'mobx-state-tree';

const Role = types.model('Role', {
  code: types.string,
  name: types.string
});

export const allRoles: IRole[] = [
  Role.create({ name: 'admin', code: 'admin' }),
  Role.create({ name: 'Authorized User', code: 'authorizedUser' }),
  Role.create({ name: 'Representative', code: 'representative' }),
  Role.create({ name: 'System User', code: 'systemUser' })
];

export default Role;
export interface IRole extends Instance<typeof Role> {}
