import { types } from 'mobx-state-tree';

export default {
  // Action
   fetchingCustomerPromotionDetail: false,
   loading: false,
   pictureLink: types.maybeNull(types.string),
   promotionLink: types.maybeNull(types.string),
   createdDate: types.maybeNull(types.Date),
   createdBy: types.maybeNull(types.string),
   updatedBy: types.maybeNull(types.string),
   id: types.maybeNull(types.string),
   showDeleteDialog: false
};
