import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { createLink, openInNewTab } from '../../../../helpers/helperFunctions';
import { cookies } from '../../models/Cookies';
import { config } from '../../models/Config';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 'max-content',
    maxWidth: 'calc(100% - 20px)'
  },
  link: {
    color: '#f0ffff',
    textDecoration: 'underLine',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textArea: {
    display: 'flex'
  }
})
);

export default observer(() => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Snackbar
      className={classes.root}
      open={!cookies.cookiesAccepted}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
        <SnackbarContent
          action={(
            <Button color='secondary' size='small' onClick={() => { cookies.setCookies(true); }}>
              {t('Accept')}
            </Button>
          )}
        message={(
          <Typography className={classes.textArea}>
            {t('This website uses cookies to ensure you get the best experience on our website')}
            {config.privacyLink ?
              <a className={classes.link} onClick={() => openInNewTab(config.privacyLink || '')} >
                {t('Privacy and Cookie Policy')}
              </a> :
              <Link className={classes.link} component={createLink('/cookie-policy')}>
                {t('Privacy and Cookie Policy')}
              </Link>}
          </Typography>
        )}
        />
    </Snackbar>
  );
});
