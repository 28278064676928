import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import queryString from 'query-string'
import { useLocation } from 'react-router-dom';
import { IRouteComponentProps } from '../../../../helpers/typeHelpers';
import Button from '../../../../sharedComponents/Button';
import Loader from '../../../../sharedComponents/Loader';
import TextField from '../../../../sharedComponents/TextField';
import { config } from '../../../base/models/Config';
import { user } from '../../../userManagement/models/User';

const BackgroundImage = lazy(() => import('../components/BackgroundImage'));

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100vh'
  },
  form: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '80%',
    maxWidth: theme.breakpoints.width('sm'),
    height: '80%',
    maxHeight: 400,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  },
  button: {
    position: 'absolute',
    bottom: theme.spacing(5),
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 300,
    padding: 10
  },
  logo: {
    position: 'absolute',
    top: 50,
    left: '10%',
    width: 120
  },
  title: {
    marginTop: 15,
    textAlign: 'center'
  },
  infoText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    textAlign: 'center'
  },
  input: {
    margin: '1rem 0rem'
  }
}));

interface IMatchParams {
  token: string;
}

interface IProps extends IRouteComponentProps<IMatchParams> {}

export default ({match}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { search } = useLocation();
  const values = queryString.parse(search);  

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const passwordRequirementText = 'Password must contain';

  useEffect(() => {
    user.clear();
    if (match.params.token) {
      user.updateInviteToken(match.params.token);
    }
  }, []);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault();
    if (values.inviteB2C === 'true') {
      user.redeemUser('', true);
      setPasswordError('');
    } else {
      if (password !== confirmPassword) {
        setPassword('');
        setConfirmPassword('');
        setPasswordError('The passwords you entered do not match');
      } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/.test(password)) {
        setPassword('');
        setConfirmPassword('');

        setPasswordError(passwordRequirementText);
      } else {
        user.redeemUser(password, false);
        setPasswordError('');
      }
    }
  };

  return (
    <div className={classes.root}>
      <img src={config.theme.logo || '/images/annata-logo.png'} alt='logo' className={classes.logo} />
      <Suspense fallback={<Loader />}>
        <BackgroundImage />
        <Paper className={classes.form}>
          <Typography className={classes.title} component='h4' variant='h4'>
            {t('Activate account')}
          </Typography>
          {values.inviteB2C === 'true' ?
            <div>
              <Typography className={classes.infoText}>
                {t('Click on button below to activate your account.')}
              </Typography>
              <form>
                <Button
                  className={classes.button}
                  disabled={user.authenticating}
                  onClick={handleSubmit}
                  type='submit'
                >
                  {t('Activate account')}
                </Button>
              </form>
            </div> :
            <div>
              <Typography className={classes.infoText} component='p'>
                {t('Set up a new password')}
              </Typography>
              <form>
                <TextField
                  autoComplete='new-password'
                  label={t('Password')}
                  type='password'
                  value={password}
                  className={classes.input}
                  displayRequiredErrorMessege={!!passwordError}
                  helperText={t(passwordError)}
                  onChange={handlePasswordChange}
                />
                <TextField
                  label={t('Confirm Password')}
                  type='password'
                  className={classes.input}
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                />
                <Button
                  className={classes.button}
                  disabled={user.authenticating}
                  onClick={handleSubmit}
                  type='submit'
                >
                  {t('Activate account')}
                </Button>
              </form>
            </div>}
          {user.updating ? <CircularProgress size='1.5rem' style={{ marginLeft: '47%' }} /> : null}
        </Paper>
      </Suspense>
    </div>
  );
};
