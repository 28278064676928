import { Instance, types } from 'mobx-state-tree';

import Actions from './Actions';
import Model from './Model';
import Views from './Views';

const CustomerPromotionList = types
  .model('CustomerPromotionList', Model)
  .actions(Actions)
  .views(Views);

export default CustomerPromotionList;

export const customerPromotionList = CustomerPromotionList.create({
orderBy: '',
ordering: 'desc',
paging: {
  pageNumber: 1,
  pageSize: 10
}}) as ICustomerPromotionList;

export interface ICustomerPromotionList extends Instance<typeof CustomerPromotionList> {
}
