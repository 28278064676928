import { types } from 'mobx-state-tree';

const ContactAddress = types.model('ContactAddress', {
  name: types.maybeNull(types.string),
  addressLine: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  country: types.maybeNull(types.string)
});

const ContactInfo = types.model('ContactInfo', {
  title: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  telephone: types.maybeNull(types.string),
  address: types.maybeNull(ContactAddress)
});

export default ContactInfo;
