import { Instance, onSnapshot, types } from 'mobx-state-tree';

import { Omit } from '../../../../helpers/typeHelpers';
import { IAccessLevel } from '../AccessLevel';
import { IRole } from '../Role';
import Actions from './Actions';
import Model from './Model';
import Views from './Views';

const User = types
  .model('User', Model)
  .actions(Actions)
  .views(Views);

export default User;

let initialState = {};

if (localStorage.getItem('user')) {
  const json = JSON.parse(localStorage.getItem('user') || '');
  if (User.is(json)) {
    initialState = {...json, ...initialState};
  }
}

export const user = User.create(initialState) as IUser;

onSnapshot(user as any, snapshot => {
  localStorage.setItem('user', JSON.stringify(snapshot));
});

export interface IUser extends Omit<Instance<typeof User>, 'accessLevels'
| 'roles'> {
  accessLevels: IAccessLevel[];
  roles: IRole[];
}

export interface IRedeemUser {
  email?: string;
  token?: string;
  userName?: string;
  password?: string;
  userLoginB2C?: boolean;
}

export interface IUserMemberResponse extends Omit<IUser,
  'userEmail' |
  'phoneNumber' |
  'createdDate' |
  'updatedDate'
> {
  dealerRoleId: string | null;
  dealerRoleName: null | null;
  id: string | null;
  userEmail: string | null;
  userId: string | null;
  userName: string | null;
  userPhone: string | null;
  createdDate: string | null;
  updatedDate: string | null;
  membershipAccessLevels: any[];
  membershipDealerRoles: any[];
}
