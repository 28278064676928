import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

import history from './config/history';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: window._env_?.A365_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history }
        }
    }
});

if (window._env_?.A365_INSTRUMENTATION_KEY) {
    ai.loadAppInsights();
    ai.trackPageView();
}

ReactPlugin.prototype.trackEvent = (event, customProperties) => {
  this._analyticsPlugin.trackEvent(event, customProperties);
};

export const appInsights = ai.appInsights;
export default Component => withAITracking(reactPlugin, Component);
export { reactPlugin };
