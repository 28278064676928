import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createButtonTheme } from '../config/theme';
import { Omit } from '../helpers/typeHelpers';

const useStyles = makeStyles((theme: Theme) => createStyles({
  btn: {
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontWeight: 400
  }
}));

interface IProps extends Omit<ButtonProps, 'classes'> {
  className?: string;
  label?: string;
  children?: React.ReactNode;
  component?: any;
}

const ButtonComponent = ({className, component, label, children, ...props}: IProps) => {
  const buttonTheme = createButtonTheme();
  const classes = useStyles();
  const variant = props.variant || 'contained';
  const color = props.color || 'primary';

  const { t } = useTranslation();

  return (
    <MuiThemeProvider theme={buttonTheme}>
      <Button
        color={color}
        className={`${className} ${classes.btn}`}
        variant={variant}
        component={component}
        {...props}
      >
        {label ? t(label) : (children || '')}
      </Button>
    </MuiThemeProvider>
  );
};

export default (ButtonComponent);
