import { cast, Instance, onSnapshot, types } from 'mobx-state-tree';
import ContactInfo from '../ContactInfo';
import { IContactInfo } from '../ContactInfo/interface';
import Theme, { ITheme } from '../Theme';

const Config = types
  .model('Config', {
    token: types.maybeNull(types.string),
    baseUrl: types.maybeNull(types.string),
    useHashRouting: false,
    privacyLink: types.maybeNull(types.string),
    privacyText: types.maybeNull(types.string),
    contactInfo: types.maybeNull(ContactInfo),
    languages: types.array(types.string),
    hideWrapper: false,
    theme: types.optional(Theme, {}),
    title: types.maybeNull(types.string),
    favIcon: types.maybeNull(types.string),
    clientId: types.maybeNull(types.string),
    authority: types.maybeNull(types.string),
    knownAuthorities: types.maybeNull(types.string),
    postLogoutRedirectUri: types.maybeNull(types.string),
    userLoginB2C: false,
    displayPortalLoginButton: true,
    systemUser: false
  })
  .actions(self => ({
    setHideWrapper(hideWrapper: boolean) {
      self.hideWrapper = hideWrapper;
    },

    updateToken(token: string) {
      self.token = token;
    },

    setBaseUrl(url: string) {
      self.baseUrl = url;
    },

    setUseHashRouting(use: boolean) {
      self.useHashRouting = use;
    },

    setTheme(theme: ITheme) {
      self.theme = theme;
    },

    setContactInfo(contactInfo: IContactInfo) {
      self.contactInfo = contactInfo;
    },

    setLanguages(langList: string[]) {
      self.languages = cast(langList);
    },

    setPrivacyLink(site: string) {
      self.privacyLink = site;
    },

    setPrivacyText(text: string) {
      self.privacyText = text;
    },

    setTitle(title: string) {
      if (title) {
        self.title = title;
      }
    },

    setFavIcon(path: string) {
      if (path) {
        self.favIcon = path;
      }
    },

    setClientId(clientId: string) {
      if (clientId) {
        self.clientId = clientId;
      }
    },

    setAuthority(authority: string) {
      if (authority) {
        self.authority = authority;
      }
    },

    setKnownAuthorities(knownAuthorities: string) {
      if (knownAuthorities) {
        self.knownAuthorities = knownAuthorities;
      }
    },

    setPostLogoutRedirectUri(postLogoutRedirectUri: string) {
      if (postLogoutRedirectUri) {
        self.postLogoutRedirectUri = postLogoutRedirectUri;
      }
    },

    updateUserLoginB2C(b2cUser: boolean) {
      self.userLoginB2C = b2cUser;
    },

    setDisplayPortalLoginButton(displayPortalLoginButton: boolean) {
    self.displayPortalLoginButton = displayPortalLoginButton;
    },

    updateSystemUser(sysUser: boolean) {
      self.systemUser = sysUser;
    }
  }))

export default Config;
export interface IConfig extends Instance<typeof Config> {}


let initialState = {};

if (localStorage.getItem('config')) {
  const json = JSON.parse(localStorage.getItem('config')!);
  if (Config.is(json)) {
    initialState = {...json, ...initialState};
  }
}

export const config = Config.create(initialState);

onSnapshot(config as any, snapshot => {
  localStorage.setItem('config', JSON.stringify(snapshot));
});

