// Because of TypeScript conflicts, this needs to be a JavaSctip file

import { types } from 'mobx-state-tree';

const translation = types.model({
    key: types.string,
    options: types.frozen()
});

const message = types.model('Link', {
  variant: types.string,
  message: types.union(types.string, translation)
});

export default message;
