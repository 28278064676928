import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';

const maintenancePlanSiteMap: ISite[] = [];
if (user.hasAccessLevel(['ServicePlanReport', 'All'])) {
  maintenancePlanSiteMap.push({
      label: 'Service Plan Report',
      path: '/maintenance/plan-report',
      children: [],
      showInMenu: true
    },
    {
      label: 'Servce Plan Lines',
      path: '/maintenance/plan-details/id',
      children: [],
      showInMenu: false
    });
  }

  if (user.hasAccessLevel(['RegisterMaintenance', 'All'])) {
    maintenancePlanSiteMap.push({
        label: 'Register Maintenance',
        path: '/maintenance/register-maintenance',
        children: [],
        showInMenu: true
      },
      {
        label: 'Update schedule service',
        path: '/maintenance/register-maintenance/update-service-plan/id',
        children: [],
        showInMenu: false
      });
    }

export default maintenancePlanSiteMap;
