import { Instance, types } from 'mobx-state-tree';

const Paging = types
  .model('Paging', {
    pageNumber: 1,
    pageSize: 10,
    totalItems: types.maybeNull(types.number),
    totalPages: types.maybeNull(types.number)
  })
  .actions((self: any) => ({
    setPageNumber(pageNumber: number) {
      self.pageNumber = pageNumber;
    },
    setPageSize(size: number) {
      self.pageSize = size;
    },
    setTotalItems(totalItems: number) {
      self.totalItems = totalItems;
    },
    setTotalPages(totalPages: number) {
      self.totalPages = totalPages;
    }
  }));

export default Paging;

export interface IPaging extends Instance<typeof Paging> {}

export interface IPagingResponse {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
