import { createBrowserHistory, createHashHistory } from 'history';
import { config } from '../modules/base/models/Config';

let history;

if (config.useHashRouting) {
  history = createHashHistory();
} else {
  history = createBrowserHistory();
}

export default history;
