import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

import { config } from '../modules/base/models/Config';

const colors = {
  annataRed: '#e31818',
  annataBlack: '#333a3d',
  annataGrey: '#a7aaac',
  annataBlue: '#5099a3',
  annataGreen: '#82ad4d',
  annataYellow: '#fdc42d',
  annataPurple: '#872e93',
  annataBrown: '#fdc42d',
  msBlue: '#4285F4'
};

export default () => createMuiTheme({
  palette: {
    primary: {
      main: config.theme.mainColor || colors.annataBlack
    },
    secondary: {
      main: config.theme.secondaryColor || colors.annataBlue
    },
    error: {
      main: config.theme.errorColor || colors.annataRed
    }
  }
});

export const createButtonTheme = () => createMuiTheme({
  palette: {
    primary: {
      main: config.theme.mainButtonColor || colors.annataBlue
    },
    secondary: {
      main: config.theme.secondaryButtonColor || colors.annataRed
    },
    error: {
      main: config.theme.errorButtonColor || red[500]
    }
  }
});

export const createFieldTheme = () => createMuiTheme({
  palette: {
    primary: {
      main: config.theme.mainFieldColor || colors.msBlue
    },
    secondary: {
      main: config.theme.secondaryFieldColor || colors.annataRed
    },
    error: {
      main: config.theme.errorFieldColor || red[500]
    }
  }
});
