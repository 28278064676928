import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  overlay: {
      backgroundColor: 'rgba(0, 0, 0, .75)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  dot: {
      height: 20,
      width: 20,
      backgroundColor: theme.palette.error.main,
      borderRadius: '50%',
      margin: '0 10px',
      animation: '$bounce 1.25s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite'
  },
  d1: {
    animationDelay: '0.1s'
  },
  d2: {
    animationDelay: '0.2s'
  },
  d3: {
    animationDelay: '0.3s'
  },
  '@keyframes bounce': {
    '0%': { transform: 'translateY(0px)' },
    '30%': { transform: 'translateY(-20px)' },
    '60%': { transform: 'translateY(0px)' },
    '100%': { transform: 'translateY(0px)' }
  }
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
        <div className={classes.loadingContainer}>
            <span className={`${classes.dot} ${classes.d1}`} />
            <span className={`${classes.dot} ${classes.d2}`} />
            <span className={`${classes.dot} ${classes.d3}`} />
        </div>
    </div>
  );
};

export default (Loader);
