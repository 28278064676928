import { activity } from '../modules/base/models/Activity';
import { config } from '../modules/base/models/Config';
import { user } from '../modules/userManagement/models/User';

export class A365Online {
  public static async Get(url: string, body: any = {}) {
    return new Promise((resolve, reject) => {
      if (!user.authToken) {
        reject('Token must be provided');
        return;
      }

      /* comment this out first because we dont need to translate the data now
      if (i18next.language) {
        if (url.includes('?')) {
          url += `&lang=${i18next.language}`;
        } else {
          url += `?lang=${i18next.language}`;
        }
      }
      */

      activity.setLoading(true);
      fetch(determineRequestUrl(url), {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
          secureProtocol: "TLSv1_3_method"
        }
      })
        .then(async (resp: any) => {
          activity.setLoading(false);
          let responseBody: any;
          if ((resp.status >= 200 && resp.status < 300) || resp.status === 406 || resp.status === 409
          || resp.status === 422) {
            const status = resp.status;
            try {
              responseBody = await resp.json();
            } catch {
              responseBody = resp;
            }

            if (status !== 200) {
              responseBody.status = status;
            }

            resolve(responseBody);
          } else {
            if (resp.status === 401) {
              user.showSigninDialog(true);
            }
            try {
              responseBody = await resp.json();
            } catch {
              responseBody = resp;
            }
            reject(responseBody);
          }
        })
        .catch((err: any) => {
          activity.setLoading(false);
          reject(err);
        });
    });
  }

  public static Post(url: string, body: any = {}, force = false) {
    return this.request(url, body, 'POST', force);
  }

  public static Put(url: string, body: any = {}) {
    return this.request(url, body, 'PUT');
  }

  public static Delete(url: string, body: any = {}) {
    return this.request(url, body, 'DELETE');
  }

  public static async request(url: string, body: any = {}, requestType: string, skipTokenCheck = false) {
    return new Promise((resolve, reject) => {
      if (!user.authToken && !skipTokenCheck) {
        reject('Token must be provided');
        return;
      }

      activity.setLoading(true);
      fetch(determineRequestUrl(url), {
        method: requestType,
        headers: {
          Authorization: `Bearer ${user.authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(async (resp: any) => {
          activity.setLoading(false);
          let responseBody: any;
          if (resp.status >= 200 && resp.status < 300) {
            try {
              responseBody = await resp.json();
            } catch {
              responseBody = resp;
            }
            resolve(responseBody);
          } else {
            if (resp.status === 401) {
              user.showSigninDialog(true);
            }
            try {
              responseBody = await resp.json();
            } catch {
              responseBody = resp;
            }
            reject(responseBody);
          }
        })
        .catch((err: any) => {
          activity.setLoading(false);
          reject(err);
        });
    });
  }

  public static async fileUpload(url: string, file: File) {
    return new Promise((resolve, reject) => {
      if (!user.authToken) {
        reject('Token must be provided');
        return;
      }

      const formData = new FormData();
      formData.append('file', file, file.name);

      fetch(determineRequestUrl(url), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.authToken}`
        },
        body: formData
      })
        .then(async (resp: any) => {
          activity.setLoading(false);
          let responseBody: any;
          if (resp.status >= 200 && resp.status < 300) {
            try {
              responseBody = await resp.json();
            } catch {
              responseBody = resp;
            }
            resolve(responseBody);
          } else {
            if (resp.status === 401) {
              user.showSigninDialog(true);
            }
            try {
              responseBody = await resp.json();
            } catch {
              responseBody = resp;
            }
            reject(responseBody);
          }
        })
        .catch((err: any) => {
          activity.setLoading(false);
          reject(err);
        });
    });
  }

  public static async Authenticate(userName: string, password: string, rememberMe: boolean) {
    return new Promise((resolve, reject) => {
      if (!config.baseUrl) {
        throw new Error('Base URL must be provided');
      }

      activity.setLoading(true);

      fetch(`${config.baseUrl}/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: userName, password, rememberMe })
      })
        .then(async resp => {
          activity.setLoading(false);
          if (resp.status >= 200 && resp.status < 300) {
            resolve({
              status: 'success',
              ...(await resp.json())
            });
          } else if (resp.status === 401) {
            resolve({
              status: 'failed',
              ...(await resp.json())
            });
          } else {
            reject(resp);
          }
        })
        .catch(err => {
          activity.setLoading(false);
          reject(err);
        });
    });
  }

  public static async Open(path: string, target = '_blank') {
    window.open(determineRequestUrl(path), target);
  }

  public static async OpenWithAuthentication(path: string, filename: string) {
    return new Promise((resolve, reject) => {
      if (!user.authToken) {
        reject('Token must be provided');
        return;
      }

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      const file = determineRequestUrl(path); 
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${user.authToken}`);
      fetch(file, { headers })
      .then(response => response.blob())
      .then(blobby => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = filename;
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
          resolve({success: true});
      })
      .catch((err: any) => {
        activity.setLoading(false);
        reject(err);
      });

    });
  }
}

export const determineRequestUrl = (url: string): string => {
  if (url.substring(0, 5) === 'https') {
    return url;
  }
  return `${config.baseUrl}/${url.replace(/^\//, '')}`;
};
